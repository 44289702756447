import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import Get from '../../../components/Get'
import useDebounce from '../../../hooks/useDebounce'
import Header from '../../../components/Header'
import { formatDateTime } from '../../../utils/datetime'
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { VehicleType } from '../../vehicles/types'
import useRouteGuard from '../../../hooks/useRouteGuard'
import { statusOptions } from '../formConfig'
import { formatBPMRatio } from '../../rdw/views/RdwList'
import useInterval from '../../../hooks/useInterval'
import { useSelector } from 'react-redux'
import { selectUser } from '../../users/authSlice'
import api from '../../../utils/api'

interface VehicleListResponse {
    count: number
    next: string
    previous: string
    results: VehicleType[]
}

const S = {
    TableView: styled.div`
        padding: 24px;
    `,
    SliderWrapper: styled.div`
        max-width: 100%;
        width: 330px;
        margin-top: 24px;

        @media (max-width: 600px) {
            margin-top: 0;
        }

        & > input {
            width: 100%;
        }
    `,
    Search: styled(Form.Control)`
        margin: 24px 0;
        max-width: 100%;
        width: 200px;

        @media (max-width: 600px) {
            width: 100%;
        }
    `,
}

const VehicleTable = ({ data }: { data: VehicleListResponse }) => (
    <>
        <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
                Total results: {data.count}
            </Typography>
        </Box>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Plate</TableCell>
                        <TableCell>VIN</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Date of Theft</TableCell>
                        <TableCell>Kiwa Certificate</TableCell>
                        <TableCell>Production Year</TableCell>
                        <TableCell>Theft Status</TableCell>
                        <TableCell>Stopheling</TableCell>
                        <TableCell>Reported Date Client</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.results?.map((vehicle) => (
                        <TableRow key={vehicle.id}>
                            <TableCell>
                                <Link to={`/vehicles/${vehicle.id}/`}>{vehicle.plate}</Link>
                            </TableCell>
                            <TableCell>{vehicle.vinNumber}</TableCell>
                            <TableCell>{vehicle.make}</TableCell>
                            <TableCell>{vehicle.model}</TableCell>
                            <TableCell>{formatDateTime(vehicle.dateOfTheft)}</TableCell>
                            <TableCell>{!isEmpty(vehicle.kiwaCertificate) ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{vehicle.dateProduction}</TableCell>
                            <TableCell>
                                {(statusOptions.find(({ value }) => value === vehicle.status) || {}).label}
                            </TableCell>
                            <TableCell>{vehicle.helingRaw ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{formatDateTime(vehicle.dateCreated)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
)

const VehicleTableForRentalOrg = ({ data }: { data: VehicleListResponse }) => (
    <>
        <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
                Total results: {data.count}
            </Typography>
        </Box>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Plate</TableCell>
                        <TableCell>VIN</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Theft Status</TableCell>
                        <TableCell>Located Country</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Renter name</TableCell>
                        <TableCell>Returned to sixt</TableCell>
                        <TableCell>Reported Date Client</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.results?.map((vehicle) => (
                        <TableRow key={vehicle.id}>
                            <TableCell>
                                <Link to={`/vehicles/${vehicle.id}/`}>{vehicle.plate}</Link>
                            </TableCell>
                            <TableCell>{vehicle.vinNumber}</TableCell>
                            <TableCell>{vehicle.make}</TableCell>
                            <TableCell>{vehicle.model}</TableCell>
                            <TableCell>
                                {(statusOptions.find(({ value }) => value === vehicle.status) || {}).label}
                            </TableCell>
                            <TableCell>
                                {vehicle.locatedCountry}
                            </TableCell>
                            <TableCell>
                                {vehicle.branch}
                            </TableCell>
                            <TableCell>
                                {vehicle.renterName}
                            </TableCell>
                            <TableCell>
                                {vehicle.recoveredDate || vehicle.recoveredLocation ? 'Yes' : '-'}
                            </TableCell>
                            <TableCell>{formatDateTime(vehicle.dateCreated)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
)

const UpdatedAt = ({ date }: { date: Date }) => {
    const now = useInterval()
    const seconds = Math.round((now.getTime() - date.getTime()) / 1000)
    return <h5>updated {seconds + 1} seconds ago</h5>
}

const seizedByOptions = [
    { value: 'any', label: 'Seized by Any', searchParams: {} },
    { value: 'api', label: 'Seized by API', searchParams: { seized_by_api: 'true' } },
    { value: 'digitpol', label: 'Seized by DIGITPOL', searchParams: { digitpol_at_seizure: 'true' } },
]

const vehicleFilterOptions = [
    { value: 'all', label: 'No filter (show all)', searchParams: {} },
    { value: 'stolen', label: 'Only stolen vehicles', searchParams: { status: 'stolen' } },
    { value: 'not_stolen', label: 'Only not stolen vehicles', searchParams: { status: 'not_stolen' } },
    { 
        value: 'stolen_with_kiwa', 
        label: 'Stolen vehicles with SCM class', 
        searchParams: { status: 'stolen', has_kiwa_certificate: 'true' } 
    },
    { 
        value: 'stolen_without_kiwa', 
        label: 'Stolen vehicles without SCM class', 
        searchParams: { status: 'stolen', has_kiwa_certificate: 'false' } 
    },
]

const RESULTS_PER_PAGE = 10

const TableView = ({ streetFilter, heading }: { streetFilter?: string; heading?: string }) => {
    useRouteGuard(['canReadVehicles', 'canReportRental'], ['canViewDatabase', 'canViewRentalReport'])
    const user = useSelector(selectUser)
    const [page, setPage] = useState(1)
    const [valueRatio, setValueRatio] = useState(0)
    const [search, setSearch] = useState('')
    const [seizedBy, setSeizedBy] = useState(seizedByOptions[0].value)
    const [vehicleFilter, setVehicleFilter] = useState(vehicleFilterOptions[0].value)
    const [organization, setOrganization] = useState('')
    const [sorting] = useState('-date_created')
    const debouncedSearch = useDebounce(search)
    const debouncedValueRatio = useDebounce(valueRatio)
    const [organizations, setOrganizations] = useState([])
    const isRentalOrganizationMember = user.organization?.type === "RENTAL"

    useEffect(() => {
        async function getOrganizations() {
            const resp = await api.get('/organizations/')
            setOrganizations(
                resp.data.results.map((e) => ({
                    label: e.name,
                    value: e.id,
                }))
            )
        }
        getOrganizations()
    }, [])

    const seizedByParams = seizedByOptions.find(({ value }) => value === seizedBy).searchParams
    const vehicleFilterParams = vehicleFilterOptions.find(({ value }) => value === vehicleFilter).searchParams
    const searchParams = new URLSearchParams({
        search: debouncedSearch,
        ordering: sorting,
        offset: `${(page - 1) * RESULTS_PER_PAGE}`,
        limit: `${RESULTS_PER_PAGE}`,
        theft_street_stripped__icontains: streetFilter || '',
        rdw__new_to_bpm_ratio__lte: `${debouncedValueRatio || ''}`,
        organization: `${organization || ''}`,
        ...seizedByParams,
        ...vehicleFilterParams,
    })
    const url = `/vehicles/?${searchParams.toString()}`

    return (
        <S.TableView>
            <Stack flexDirection={{ md: 'row' }} justifyContent={{ md: 'space-between' }}>
                <Header>{heading || 'SMV Intelligence Platform'}</Header>

                <Stack flexDirection={{ sm: 'row' }} justifyContent={{ sm: 'space-between' }}>
                    {!isRentalOrganizationMember && <S.SliderWrapper>
                        <p style={{ fontSize: 12 }}>
                            {valueRatio === 0
                                ? `BPM/value filter disabled`
                                : `BPM/value below ${(valueRatio * 100).toFixed(1)}%`}
                        </p>
                        <input
                            type="range"
                            min="0"
                            max="0.5"
                            step="0.005"
                            value={valueRatio}
                            onChange={(event) => setValueRatio(+event.target.value)}
                        />
                    </S.SliderWrapper>}

                    <FormControl
                        sx={{
                            mx: { sm: 1 },
                            marginTop: '24px',
                            width: 160,
                            minWidth: 160,
                            '& .MuiInputBase-inputSizeSmall': { fontSize: '14px' },
                        }}
                        size="small"
                    >
                        <InputLabel id="seized-by-options">Seized by</InputLabel>
                        <Select
                            labelId="seized-by-options"
                            value={seizedBy}
                            label="Seized by"
                            onChange={(e) => setSeizedBy(e.target.value)}
                        >
                            {seizedByOptions.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        sx={{
                            mx: { sm: 1 },
                            marginTop: '24px',
                            width: 250,
                            minWidth: 250,
                            '& .MuiInputBase-inputSizeSmall': { fontSize: '14px' },
                        }}
                        size="small"
                    >
                        <InputLabel id="vehicle-filter-options">Vehicle Filter</InputLabel>
                        <Select
                            labelId="vehicle-filter-options"
                            value={vehicleFilter}
                            label="Vehicle Filter"
                            onChange={(e) => setVehicleFilter(e.target.value)}
                        >
                            {vehicleFilterOptions.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {!user.organization && (
                        <FormControl
                            sx={{
                                mx: { sm: 1 },
                                marginTop: '24px',
                                width: 160,
                                minWidth: 160,
                                '& .MuiInputBase-inputSizeSmall': { fontSize: '14px' },
                            }}
                            size="small"
                        >
                            <InputLabel id="organization">Organization</InputLabel>
                            <Select
                                labelId="organization"
                                id="organization"
                                value={organization}
                                label="Organization"
                                onChange={(evt) => setOrganization(evt.target.value)}
                            >
                                <MenuItem key={-1} value={''} sx={{ fontSize: '14px' }}>
                                    All
                                </MenuItem>
                                {organizations.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{ fontSize: '14px' }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <S.Search
                        placeholder="Search"
                        onChange={(e) => {
                            setSearch(e.target.value)
                            setPage(1)
                        }}
                    />
                </Stack>
            </Stack>

            <Get url={url} interval={30}>
                {({ data }: { data: VehicleListResponse }) => (
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <UpdatedAt date={new Date()} />
                            {user.canReadVehicles || user.canReportRental || user.isSuperuser ? (
                                <Button variant="outlined">
                                    <Link to="/map">Map</Link>
                                </Button>
                            ) : (
                                <div />
                            )}
                        </Box>
                        {isRentalOrganizationMember ? <VehicleTableForRentalOrg data={data} /> : <VehicleTable data={data} />}
                        <Pagination
                            sx={{ mt: 2 }}
                            count={Math.ceil(data.count / RESULTS_PER_PAGE)}
                            shape="rounded"
                            onChange={(_, _page) => setPage(_page)}
                            page={page}
                            color="primary"
                            siblingCount={0}
                        />
                    </>
                )}
            </Get>
        </S.TableView>
    )
}

export default TableView
