import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { isEmpty } from "lodash";
import Get from "../../../components/Get";
import { VehicleType, HistoryEntry } from "../types";
import VehicleInfo from "../components/VehicleInfo";
import {
	isAdministrationField,
	isDetailsField,
	isSeizureField,
	isTelematicsField,
	isAssetField,
	isKiwaCertificateFields,
	detailFields,
	isVbvTrackingFields,
	isRentalReportFields,
	isRecoveryReportFields,
} from "../fields";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../users/authSlice";
import useRouteGuard from "../../../hooks/useRouteGuard";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Grid,
	Paper,
	Snackbar,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { camelToString, fields } from "../../vin-search/VinSearchItem";
import RDWTables from "../../rdw/components/RDWTables";
import UpdateLog from "../components/UpdateLog";
import GroupFields from "../../../components/GroupFields";
import InternalNotes from "../components/InternalNotes";
import { useEffect, useState } from "react";
import api from "../../../utils/api";
import { setIsPageLoading } from "../../../appSlice";
import env from "../../../constants/env";
import StophelingSection from "../components/StophelingSection";
import React from "react";

const S = {
	Page: styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
	Buttons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    @media print {
      display: none;
    }
    @media screen and (max-width: 900px) {
      margin-top: 16px;
      justify-content: flex-start;
    }
  `,
	ButtonLink: styled(Link)`
    font-size: 12px;
  `,
	ColWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
	Col: styled.div`
    padding: 24px;
    width: 50%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  `,
	Wrapper: styled.div`
    position: relative;
    background: white;
    z-index: 9;
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
  `,
	Td: styled.td`
    width: 50%;
    text-transform: capitalize;
  `,
};

export const sortByKey = (a, b) => {
	if (a.key < b.key) {
		return -1;
	}
	if (a.key > b.key) {
		return 1;
	}
	return 0;
};

const RDW = (props: { plate: string }) => {
	const plate = (props.plate || "-").toUpperCase();
	return (
		<Get url={`/rdw/${plate}/`} defaultErrorMessage="Plate not found">
			{(props) => <RDWTables {...props} />}
		</Get>
	);
};

const PreviewDialog = ({
	open,
	onClose,
	emailData,
	loading,
	sendEndpoint,
	error,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState("Netherlands");

	const handleSubmit = async () => {
		setIsSubmitting(true);
		setSuccess(false);

		const response = await api.post(
			`/vehicles/${emailData.vehicleId}/${sendEndpoint}`,
			{
				location: selectedCountry,
			},
		);

		setIsSubmitting(false);

		if (response.error) {
			console.error("Failed to send email:", response.error);
			return;
		}

		setSuccess(true);
		setTimeout(() => {
			onClose();
			setSuccess(false);
		}, 2000);
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>{emailData?.subject || "Email Preview"}</DialogTitle>
			<DialogContent>
				{loading ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							py: 4,
						}}
					>
						<CircularProgress />
						<Typography sx={{ ml: 2 }}>Loading preview...</Typography>
					</Box>
				) : error ? (
					<Typography
						sx={{
							color: "#d32f2f",
							fontSize: "0.9rem",
							padding: "16px",
							backgroundColor: "#ffebee",
							borderRadius: "4px",
							border: "1px solid #ffcdd2",
						}}
					>
						{error}
					</Typography>
				) : (
					<>
						<FormControl fullWidth sx={{ mb: 2 }}>
							<InputLabel>Country</InputLabel>
							<Select
								value={selectedCountry}
								label="Country"
								onChange={(e) => setSelectedCountry(e.target.value)}
							>
								<MenuItem value="Netherlands">Netherlands</MenuItem>
								<MenuItem value="Hungary">Hungary</MenuItem>
								<MenuItem value="Serbia">Serbia</MenuItem>
							</Select>
						</FormControl>
						<Typography
							sx={{
								whiteSpace: "pre-wrap",
								fontFamily: "monospace",
								fontSize: "0.9rem",
								padding: "16px",
								backgroundColor: "#f5f5f5",
								borderRadius: "4px",
							}}
						>
							{emailData?.textContent || ""}
						</Typography>
					</>
				)}
				{success && (
					<Typography
						sx={{
							color: "#2e7d32",
							fontSize: "0.9rem",
							padding: "16px",
							backgroundColor: "#e8f5e9",
							borderRadius: "4px",
							border: "1px solid #c8e6c9",
							mt: 2,
						}}
					>
						Email sent successfully!
					</Typography>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
				{!error && (
					<Button
						onClick={handleSubmit}
						variant="contained"
						color="primary"
						disabled={isSubmitting || loading}
						startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
					>
						{isSubmitting ? "Sending..." : "Send Email"}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

const VehicleView = (props: RouteComponentProps<{ id: string }>) => {
	useRouteGuard(
		["canReadVehicles", "canReportRental"],
		["canViewDatabase", "canViewRentalReport"],
	);
	const user = useSelector(selectUser);
	const canEdit =
		user.isSuperuser || user.canEditVehicles || user.canReportRental;
	const id = props.match.params.id;
	const [data, setData] = useState<VehicleType | undefined>(undefined);
	const [histories, setHistories] = useState<VehicleType["history"]>([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [emailPreviewData, setEmailPreviewData] = useState(null);
	const [previewLoading, setPreviewLoading] = useState(false);
	const [previewError, setPreviewError] = useState<string | null>(null);
	const [sendEndpoint, setSendEndpoint] = useState("send-location-email/");
	const dispatch = useDispatch();
	const mounted = React.useRef(true);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	const handlePreviewEmail = async () => {
		setPreviewOpen(true);
		setPreviewLoading(true);
		setSendEndpoint("send-location-email/");
		setPreviewError(null);

		const response = await api.post(`/vehicles/${id}/preview-location-email/`);

		if (!mounted.current) return;

		setPreviewLoading(false);

		const error = (response.error as any)?.error || response.error;
		if (error) {
			console.error("Failed to send email:", error);
		setPreviewError(
				typeof error === "string"
					? error
					: "Failed to send email. No country or GPS data found.",
			);
			return;
		}

		setEmailPreviewData({
			...response.data,
			vehicleId: id,
		});
	};

	const handlePreviewSpareKeyEmail = async () => {
		setPreviewOpen(true);
		setPreviewLoading(true);
		setSendEndpoint("send-spare-key-email/");
		setPreviewError(null);

		const response = await api.post(`/vehicles/${id}/preview-spare-key-email/`);

		if (!mounted.current) return;

		setPreviewLoading(false);

		const error = (response.error as any)?.error || response.error;
		if (error) {
			console.error("Failed to send email:", error);
			setPreviewError(
				typeof error === "string" ? error : "Failed to send email.",
			);
			return;
		}

		setEmailPreviewData({
			...response.data,
			vehicleId: id,
		});
	};

	const handlePreviewMissingCarEmail = async () => {
		setPreviewOpen(true);
		setPreviewLoading(true);
		setSendEndpoint("send-missing-car-email/");
		setPreviewError(null);

		const response = await api.post(
			`/vehicles/${id}/preview-missing-car-email/`,
		);

		if (!mounted.current) return;

		setPreviewLoading(false);

		const error = (response.error as any)?.error || response.error;
		if (error) {
			console.error("Failed to send email:", error);
			setPreviewError(
				typeof error === "string"
					? error
					: "Failed to send email. Please check if all required fields are filled.",
			);
			return;
		}

		setEmailPreviewData({
			...response.data,
			vehicleId: id,
		});
	};

	useEffect(() => {
		async function fetchData() {
			try {
				dispatch(setIsPageLoading(false));
				const vehicleResp = await api.get(`/vehicles/${id}/`);
				const data = vehicleResp.data;
				const rentalReportData = data.rentalReport || {
					theftReport: [],
					insuranceDocs: [],
					vehicleRegistrationPapers: [],
					authorizationToTowDocs: [],
				};

				if (!mounted.current) return;

				setData({
					...vehicleResp.data,
					theftReport: [...data.theftReport, ...rentalReportData.theftReport],
					insuranceDocs: [
						...data.insuranceDocs,
						...rentalReportData.insuranceDocs,
					],
					vehicleRegistrationPapers: [
						...data.vehicleRegistrationPapers,
						...rentalReportData.vehicleRegistrationPapers,
					],
					authorizationToTowDocs: [
						...data.authorizationToTowDocs,
						...rentalReportData.authorizationToTowDocs,
					],
				});
				const historiesResp = await api.get(`/vehicles/${id}/histories/`);
				if (!mounted.current) return;
				setHistories(historiesResp.data);
			} catch (err) {
				console.log(err);
			} finally {
				if (mounted.current) {
					dispatch(setIsPageLoading(false));
				}
			}
		}
		fetchData();
	}, [id]);

	const getCoordinates = (lastLocatedAt: string) => {
		if (!lastLocatedAt) return null;
		const [lat, long] = lastLocatedAt
			.split("/")
			.map((coord) => parseFloat(coord.trim()));
		if (isNaN(lat) || isNaN(long)) return null;
		return { lat, long };
	};

	return (
		<S.Page>
			{data && (
				<>
					<Box sx={{ padding: "24px" }}>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="space-between"
							mb={2}
						>
							<Grid item xs={12} md={6}>
								<Typography variant="h4">SMVIU Data</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<S.Buttons>
									{canEdit && (
										<S.ButtonLink to={`/vehicles/${id}/edit/`}>
											<Button variant="outlined" size="small">
												Edit
											</Button>
										</S.ButtonLink>
									)}
									<Button
										variant="outlined"
										size="small"
										onClick={handlePreviewEmail}
										sx={{ mr: 1 }}
									>
										Request to NCB
									</Button>
									{env.isSixt && (
										<>
											<Button
												variant="outlined"
												size="small"
												onClick={handlePreviewSpareKeyEmail}
												sx={{ mr: 1 }}
											>
												Request Spare Key
											</Button>
											<Button
												variant="outlined"
												size="small"
												onClick={handlePreviewMissingCarEmail}
												sx={{ mr: 1 }}
											>
												Missing Car Report
											</Button>
										</>
									)}
									<S.ButtonLink to={`/vehicles/${id}/telematics-report/`}>
										<Button variant="outlined" size="small">
											Telematics Report
										</Button>
									</S.ButtonLink>

									<S.ButtonLink to={`/vehicles/${id}/seizure-report/`}>
										<Button variant="outlined" size="small">
											Seizure Report
										</Button>
									</S.ButtonLink>

									<S.ButtonLink to={`/vehicles/${id}/vehicle-report/`}>
										<Button variant="outlined" size="small">
											Vehicle Report
										</Button>
									</S.ButtonLink>
								</S.Buttons>
							</Grid>
						</Grid>
						<Grid container spacing={2} alignItems="stretch">
							<Grid item xs={12} lg={6}>
								<Paper sx={{ p: 2, height: "100%" }}>
									<VehicleInfo
										header="Vehicle Details"
										fieldFilter={isDetailsField}
										vehicle={data}
										compact
										fieldSorting={(a, b) => {
											return (
												// @ts-ignore
												detailFields.indexOf(a.key) -
												// @ts-ignore
												detailFields.indexOf(b.key)
											);
										}}
									/>

									<VehicleInfo
										header="Files"
										fieldFilter={isAssetField}
										vehicle={data}
										compact
									/>

									<VehicleInfo
										header="Telematics / GPS Data"
										fieldFilter={isTelematicsField}
										vehicle={{
											...data,
											...data.kiwaCertificate,
											scmClass: !isEmpty(data.kiwaCertificate),
										}}
										compact
										actionsRender={() => (
											<div
												style={{
													display: "flex",
													gap: "8px",
													alignItems: "center",
												}}
											>
												{data.hasLocation ? (
													<Button variant="outlined">
														<Link to={`/map?vehicleId=${data.id}&mode=detail`}>
															Map
														</Link>
													</Button>
												) : null}
												{data.tracker &&
												(user.canReadVehicles ||
													user.isSuperuser ||
													user.canReportRental) ? (
													<Button variant="outlined">
														<Link to={`/trackers?imei=${data.tracker}`}>
															Tracker
														</Link>
													</Button>
												) : null}
											</div>
										)}
									/>

									{!isEmpty(data.kiwaCertificate) && (
										<VehicleInfo
											header="SCM Class"
											fieldFilter={isKiwaCertificateFields}
											vehicle={{ ...data, ...data.kiwaCertificate }}
											compact
										/>
									)}

									{!isEmpty(data.vbvTrackingLog) && (
										<VehicleInfo
											header="Vbv Sent Updates"
											fieldFilter={isVbvTrackingFields}
											vehicle={{ ...data, ...data.vbvTrackingLog }}
											compact
										/>
									)}

									{!isEmpty(data.rentalReport) && (
										<VehicleInfo
											header="Rental Report"
											fieldFilter={isRentalReportFields}
											vehicle={{ ...data.rentalReport }}
											compact
										/>
									)}
								</Paper>
							</Grid>

							<Grid item xs={12} lg={6}>
								<Paper sx={{ p: 2, height: "100%" }}>
									<InternalNotes vehicleId={id} />

									<VehicleInfo
										header="Seizure Details"
										fieldFilter={isSeizureField}
										vehicle={data}
										compact
									/>

									<VehicleInfo
										header="Recovery report"
										fieldFilter={isRecoveryReportFields}
										vehicle={data}
										compact
									/>

									<VehicleInfo
										fieldSorting={sortByKey}
										header="Admin Details"
										fieldFilter={isAdministrationField}
										vehicle={data}
										compact
									/>

									<UpdateLog logs={histories} />
								</Paper>
							</Grid>
						</Grid>

						{data.webData && (
							<Paper sx={{ p: 2, my: 2 }}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg={6}>
										<GroupFields
											data={data.webData}
											settings={[
												{
													group: "Web hits",
													fields: fields.map((field) => ({
														field,
														label: camelToString(field),
													})),
												},
											]}
										/>
									</Grid>
								</Grid>
							</Paper>
						)}

						<Divider style={{ marginBottom: 20, marginTop: 36 }} />
					</Box>
					{!env.isSixt && (
						<Box style={{ padding: 24 }}>
							<Typography variant="h4" gutterBottom>
								RDW Data
							</Typography>
							<RDW plate={data.plate} />
						</Box>
					)}
					{data?.helingRaw && (
						<StophelingSection
							vehicleId={id}
							data={{
								helingRaw: data.helingRaw,
								helingStatus: data.helingStatus,
								helingLastChecked: data.helingLastChecked,
							}}
						/>
					)}
					<PreviewDialog
						open={previewOpen}
						onClose={() => {
							setPreviewOpen(false);
							setEmailPreviewData(null);
							setPreviewError(null);
						}}
						emailData={emailPreviewData}
						loading={previewLoading}
						sendEndpoint={sendEndpoint}
						error={previewError}
					/>
				</>
			)}
		</S.Page>
	);
};

export default VehicleView;
