import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Popover,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import ContentCopy from "@mui/icons-material/ContentCopy";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SortIcon from "@mui/icons-material/Sort";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../utils/api";
import { GeoLocation } from "../vehicles/types";
import styled, { css } from "styled-components";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import { Search } from "@mui/icons-material";
import useDebounce from "../../hooks/useDebounce";
import NextPrevPagination from "../../components/NextPrevPagination";
import Map from "./Map";
import useRefreshSecondAgo from "../../hooks/useRefreshSecondAgo";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showNotification } from "../notifications";
import { useDispatch } from "react-redux";
import { getCopiedContent, getGpsCoordinates } from "./utils";
import useRouteGuard from "../../hooks/useRouteGuard";

const S = {
	InfoBox: styled.div`
    width: 320px;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
  `,
	Sidebar: styled.div<{ collapse?: boolean; isSingle?: boolean }>`
    position: fixed;
    top: 70px;
    left: 6px;
    display: grid;
    grid-template-rows: ${({ collapse }) => (collapse ? "24px" : "48px 1fr 48px 20px")};
    width: 300px;
    height: ${({ collapse }) => (collapse ? "44px" : "85vh")};
    padding: 12px;
    padding-bottom: 16px;
    overflow: hidden;
    border-radius: 8px;
    background: #0e232a;
    z-index: 100;

    ${({ isSingle }) =>
			isSingle &&
			css`
        grid-template-rows: 1fr 20px;
      `}

    @media (max-width: 768px) {
      width: 100%;
      left: 0;
      border-radius: 0;
      height: 50vh;
      bottom: 0;
      top: auto;
    }
  `,
	MapContainer: styled.div<{ isFullscreen?: boolean }>`
    width: 100vw;
    height: calc(100vh - 95px);
    position: relative;
    transition: height 0.3s ease;

    @media (max-width: 768px) {
      height: ${({ isFullscreen }) => (isFullscreen ? "calc(100vh - 95px)" : "50vh")};
    }
  `,
	FullscreenButton: styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    z-index: 999;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 769px) {
      display: none;
    }

    svg {
      font-size: 24px;
    }
  `,
	VehicleItem: styled.div<{
		isActive?: boolean;
		isStolen?: boolean;
		isSeized?: boolean;
		hasEmailSent?: boolean;
	}>`
    position: relative;
    width: 100%;
    margin-bottom: 8px;
    padding: 8px;
    background: ${({ isActive }) => (isActive ? "#0d2d38" : "#1c586d")};
    color: white;
    border-radius: 3px;
    border-left: 5px solid ${({ isActive, isSeized, hasEmailSent }) => {
			// Base colors
			if (isSeized) {
				return isActive ? "#2a692a" : "#00ff00"; // Green/Dark green
			}
			if (hasEmailSent) {
				return isActive ? "#ba8a30" : "#ffa500"; // Orange/Dark orange
			}
			return isActive ? "#942626" : "#ff0000"; // Red/Dark red
		}};
    user-select: none;
    cursor: pointer;
    svg {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      padding: 12px;
      margin-bottom: 4px;
    }
  `,
	CopyIcon: styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
  `,
	CopyGpsIcon: styled.div`
    position: absolute;
    top: 8px;
    right: 36px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
  `,
	GoogleMapsIcon: styled.div`
    position: absolute;
    top: 8px;
    right: 64px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
  `,
	MobileMenuIcon: styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  `,
	RefreshIndicator: styled.div<{ refreshAgo: number }>`
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    background: #1c586d;
    color: gray;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    & > div {
      width: ${({ refreshAgo }) => `${(refreshAgo / 60) * 100}%`};
      height: 24px;
      white-space: nowrap;
      background: #2288ab;
      transition: 0.5s;
    }

    & > p {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  `,
	CollapseButton: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22px;
    background: white;
    display: grid;
    place-items: center;
    cursor: pointer;
  `,
	List: styled.div`
    margin-top: 10px;
    padding-right: 4px;
    overflow: auto;
  `,
	HideHistory: styled.div`
    position: absolute;
    top: 68px;
    right: 8px;
    font-weight: bold;
    color: white;
    background: #1c586d;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #18779a;
    }
  `,
	MobileToggle: styled.div`
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: #0e232a;
    color: white;
    z-index: 99;
    cursor: pointer;
    text-align: center;
    padding: 8px;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  `,
};

const LIMIT = 999;
type Colors = "green" | "orange" | "red";

const MapView = () => {
	useRouteGuard(
		["canReadVehicles", "canReportRental"],
		["canViewDatabase", "canViewRentalReport"],
	);
	const [colorFilter, setColorFilter] = useState<Colors>(null);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const params = new URLSearchParams(location.search);
	const vehicleId = params.get("vehicleId");
	const mode = params.get("mode");
	const [markers, setMarkers] = useState<GeoLocation[]>([]);
	const [selectedMarkerId, setSelectedMarkerId] = useState<number | undefined>(
		undefined,
	);
	const { count: refreshAgo, reset } = useRefreshSecondAgo(60);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search);
	const [collapse, setCollapse] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const [sortBy, setSortBy] = useState("created");
	const [showAll, setShowAll] = useState(false);
	const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
	const [activeItemId, setActiveItemId] = useState<number | null>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const [mobileListVisible, setMobileListVisible] = useState(true);
	const [isMapFullscreen, setIsMapFullscreen] = useState(false);

	const handleSort = (e) => {
		setSortBy(e.target.value);
		setAnchorEl(null);
	};

	const openSortMenu = Boolean(anchorEl);

	const colorQueryParam = useMemo(() => {
		if (colorFilter === "green") return "is_seized=true";
		if (colorFilter === "orange") return "has_email_sent=true";
		if (colorFilter === "red") return "is_stolen=true";
		return "";
	}, [colorFilter]);

	useEffect(() => {
		async function fetchData() {
			reset();

			if (mode === "detail") {
				const resp = await api.get<GeoLocation>(
					`/vehicles/${vehicleId}/location/`,
				);
				if (resp.data) {
					setMarkers([resp.data]);
					setSelectedMarkerId(resp.data.id);
					setTotal(1);
				}
				return;
			}

			console.log(colorQueryParam);
			const resp = await api.get<{ results: GeoLocation[]; count: number }>(
				`/vehicles/locations/?offset=${
					(page - 1) * LIMIT
				}&limit=${LIMIT}&search=${debouncedSearch}&${colorQueryParam ? `${colorQueryParam}&` : ""}&sort=${sortBy}&all=${showAll ? "true" : ""}`,
			);
			if (resp.data) {
				setMarkers(resp.data.results);
				if (resp.data.results?.length) {
					setSelectedMarkerId((id) => id || resp.data.results[0].id);
				}
				setTotal(resp.data.count);
			}
		}

		fetchData();

		const fetchDataInterval = setInterval(() => {
			fetchData();
		}, 60 * 1000);

		return () => clearInterval(fetchDataInterval);
	}, [colorQueryParam, page, debouncedSearch, sortBy, showAll]);

	const handleClickVehicle = useCallback(
		(item: GeoLocation, event?: React.MouseEvent) => {
			if (isMobile) {
				event?.stopPropagation();
				setActiveItemId(activeItemId === item.id ? null : item.id);
			} else if (mode !== "detail") {
				history.push(`/map?vehicleId=${item.vehicle.id}`);
				setSelectedMarkerId(item.id);
			}
		},
		[isMobile, mode, activeItemId],
	);

	const handleCopyAll = (event, item) => {
		event.stopPropagation();
		navigator.clipboard.writeText(getCopiedContent(item));
		dispatch(showNotification({ body: "Copied!" }));
	};

	const handleCopyGPS = (event, item) => {
		event.stopPropagation();
		navigator.clipboard.writeText(getGpsCoordinates(item));
		dispatch(showNotification({ body: "GPS coordinates copied!" }));
	};

	const handleOpenGoogleMaps = (event, item) => {
		event.stopPropagation();
		window.open(
			`https://www.google.com/maps?q=${item.lat},${item.long}`,
			"_blank",
		);
	};

	const handleVehicleDetailView = (event, item) => {
		event.stopPropagation();
		window.open(`/vehicles/${item.vehicle.id}`, "_blank");
	};

	return (
		<Box
			width={"100vw"}
			height={"calc(100vh - 95px)"}
			style={{ display: "flex", flexDirection: "column" }}
		>
			<S.MapContainer isFullscreen={isMapFullscreen}>
				<Map
					markers={markers}
					selectedMarkerId={selectedMarkerId}
					handleClickVehicle={handleClickVehicle}
					vehicleId={vehicleId}
				/>
				<S.FullscreenButton
					onClick={() => setIsMapFullscreen(!isMapFullscreen)}
				>
					{isMapFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
				</S.FullscreenButton>
			</S.MapContainer>

			<S.Sidebar
				isSingle={mode === "detail"}
				style={{ display: isMapFullscreen ? "none" : undefined }}
			>
				{mode !== "detail" && !collapse && (
					<Box display="flex" alignItems="center">
						<TextField
							id="input-with-icon-textfield"
							label="Search by plate"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							}}
							variant="filled"
							size="small"
							fullWidth
							sx={{ background: "white" }}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<IconButton
							edge="end"
							color="info"
							onClick={(e) => setAnchorEl(e.currentTarget)}
							aria-label="close"
							sx={{ ml: 1 }}
						>
							<SortIcon />
						</IconButton>
						<Popover
							open={openSortMenu}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<FormControl sx={{ p: 2 }}>
								<Typography>Sort by</Typography>
								<RadioGroup
									aria-labelledby="demo-controlled-radio-buttons-group"
									name="controlled-radio-buttons-group"
									value={sortBy}
									onChange={handleSort}
								>
									<FormControlLabel
										sx={{
											mb: 0,
											mr: 0,
											".MuiFormControlLabel-label": { fontSize: "12px" },
										}}
										value="date"
										control={<Radio />}
										label="Latest position"
									/>
									<FormControlLabel
										sx={{
											mb: 0,
											mr: 0,
											".MuiFormControlLabel-label": { fontSize: "12px" },
										}}
										value="created"
										control={<Radio />}
										label="Latest received position"
									/>
								</RadioGroup>
								<Divider />
								<FormControlLabel
									sx={{ mt: 2 }}
									control={
										<Checkbox
											checked={showAll}
											onChange={(e) => setShowAll(e.target.checked)}
										/>
									}
									label="Show all vehicles"
								/>
							</FormControl>
							<Divider />
							<FormControl sx={{ p: 2 }}>
								<Typography>Filter by status</Typography>
								<RadioGroup
									aria-labelledby="color-filter-radio-group"
									name="color-filter-radio-group"
									value={colorFilter}
									onChange={(e) => setColorFilter(e.target.value as Colors)}
								>
									{[
										{
											value: null,
											label: "All",
										},
										{
											value: "green",
											label: "Seized",
										},
										{
											value: "orange",
											label: "Interpol Alert",
										},
										{
											value: "red",
											label: "Stolen",
										},
									].map((config) => (
										<FormControlLabel
											key={config.label}
											sx={{
												mb: 0,
												mr: 0,
												".MuiFormControlLabel-label": {
													fontSize: "12px",
													backgroundColor: config.value || "transparent",
													color: config.value ? "white" : "inherit",
													padding: "2px 8px",
													borderRadius: "4px",
												},
											}}
											value={config.value}
											control={<Radio />}
											label={config.label}
										/>
									))}
								</RadioGroup>
							</FormControl>
						</Popover>
					</Box>
				)}

				<S.List>
					{markers.map((item) => (
						<S.VehicleItem
							isActive={item.id === selectedMarkerId}
							onClick={(e) => handleClickVehicle(item, e)}
							key={item.id}
							isStolen={item.vehicle?.status === "stolen"}
							isSeized={item.isSeized}
							hasEmailSent={!!item.vehicleLocationEmailSentAt}
						>
							{isMobile && activeItemId === item.id ? (
								<>
									<Box
										sx={{
											display: "grid",
											gap: 1,
											height: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												mb: 1,
											}}
										>
											<Typography variant="body1" sx={{ color: "white" }}>
												{item.vehicle.plate} - {item.vehicle.model}
											</Typography>
											<IconButton
												size="small"
												onClick={(e) => {
													e.stopPropagation();
													setActiveItemId(null);
												}}
												sx={{ color: "white" }}
											>
												<KeyboardArrowUpIcon />
											</IconButton>
										</Box>
										<Button
											fullWidth
											variant="contained"
											size="small"
											startIcon={<LocationOnIcon />}
											onClick={(e) => handleVehicleDetailView(e, item)}
											sx={{
												justifyContent: "flex-start",
												bgcolor: "rgba(255,255,255,0.1)",
											}}
										>
											Vehicle Detail View
										</Button>
										<Button
											fullWidth
											variant="contained"
											size="small"
											startIcon={<ContentCopy />}
											onClick={(e) => handleCopyAll(e, item)}
											sx={{
												justifyContent: "flex-start",
												bgcolor: "rgba(255,255,255,0.1)",
											}}
										>
											Copy all info
										</Button>
										<Button
											fullWidth
											variant="contained"
											size="small"
											startIcon={<LocationOnIcon />}
											onClick={(e) => handleCopyGPS(e, item)}
											sx={{
												justifyContent: "flex-start",
												bgcolor: "rgba(255,255,255,0.1)",
											}}
										>
											Copy GPS coordinates
										</Button>
										<Button
											fullWidth
											variant="contained"
											size="small"
											startIcon={
												<LocationOnIcon style={{ color: "#4285F4" }} />
											}
											onClick={(e) => handleOpenGoogleMaps(e, item)}
											sx={{
												justifyContent: "flex-start",
												bgcolor: "rgba(255,255,255,0.1)",
											}}
										>
											Open in Google Maps
										</Button>
									</Box>
								</>
							) : (
								<>
									{!isMobile && (
										<>
											<S.CopyIcon onClick={(e) => handleCopyAll(e, item)}>
												<ContentCopy />
											</S.CopyIcon>
											<S.CopyGpsIcon onClick={(e) => handleCopyGPS(e, item)}>
												<LocationOnIcon />
											</S.CopyGpsIcon>
											<S.GoogleMapsIcon
												onClick={(e) => handleOpenGoogleMaps(e, item)}
											>
												<LocationOnIcon style={{ color: "#4285F4" }} />
											</S.GoogleMapsIcon>
										</>
									)}

									<Link
										to={`/vehicles/${item.vehicle.id}`}
										style={{ color: "white" }}
										target="_blank"
										rel="noopener noreferrer"
										onClick={(e) => e.stopPropagation()}
									>
										<Typography variant="body1">
											{item.vehicle.plate} - {item.vehicle.model}
										</Typography>
									</Link>

									<Typography variant="body2" sx={{ my: 0.5 }}>
										<LocationOnIcon className="mr-1" />
										{item.location || `${item.lat} ${item.long}`}
									</Typography>
									<Typography
										variant="caption"
										fontStyle="italic"
										color="#43b0f4"
									>
										<AccessTimeIcon style={{ marginTop: -2, marginRight: 4 }} />
										{format(new Date(item.date), "MMM dd yyyy - HH:mm")}
									</Typography>

									{item.vehicle?.status === "stolen" && (
										<>
											<Divider sx={{ borderColor: "white", mt: 1 }} />
											<Typography variant="overline">Stolen at</Typography>
											<Typography variant="body2">
												<LocationOnIcon className="mr-1" />
												{`${item.vehicle.theftLocationStreet} ${item.vehicle.theftLocationCity}`}
											</Typography>
											<Typography
												variant="caption"
												fontStyle="italic"
												color="#43b0f4"
											>
												<AccessTimeIcon
													style={{ marginTop: -2, marginRight: 4 }}
												/>
												{format(
													new Date(item.vehicle.dateOfTheft),
													"MMM dd yyyy - HH:mm",
												)}
											</Typography>
										</>
									)}
								</>
							)}
						</S.VehicleItem>
					))}
				</S.List>
				{mode !== "detail" && (
					<NextPrevPagination
						limit={LIMIT}
						page={page}
						onPageChange={setPage}
						total={total}
					/>
				)}
				<S.CollapseButton onClick={() => setCollapse(!collapse)}>
					<KeyboardArrowUpIcon
						style={{ transform: `rotate(${collapse ? 180 : 0}deg)` }}
					/>
				</S.CollapseButton>

				<S.RefreshIndicator refreshAgo={refreshAgo}>
					<div />
					<p>
						<CachedIcon style={{ color: "gray" }} /> {refreshAgo}s ago
					</p>
				</S.RefreshIndicator>
			</S.Sidebar>
			{mode !== "detail" && vehicleId && (
				<S.HideHistory onClick={() => history.push("/map")}>
					Close vehicle view
				</S.HideHistory>
			)}
			{isMobile && (
				<S.MobileToggle
					onClick={() => setMobileListVisible(!mobileListVisible)}
				>
					<KeyboardArrowUpIcon
						style={{
							transform: `rotate(${mobileListVisible ? 180 : 0}deg)`,
							transition: "transform 0.3s ease",
						}}
					/>
					{mobileListVisible ? "Hide List" : "Show List"}
				</S.MobileToggle>
			)}
		</Box>
	);
};

export default Sentry.withErrorBoundary(MapView, {
	fallback: <p className="error"></p>,
});
