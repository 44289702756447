import { Line, Doughnut, Bar } from "react-chartjs-2";
import Get from "../../components/Get";
import { VehicleType } from "../vehicles/types";
import styled from "styled-components";
import useRouteGuard from "../../hooks/useRouteGuard";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const S = {
    Page: styled.div`
      margin-top: -15px;
      margin-bottom: -24px;
      background-color: #171c34;
      min-height: calc(100vh - 78px);
      justify-content: center;
    `,
    ReportsOverTime: styled.div`
      width: 600px;
      height: 300px;
    `,
    Summary: styled.div`
      font-size: 24px;
      color: white;
      margin: auto 0;
      padding-left: 48px;
    `,
    SummaryText: styled.span`
      font-size: 24px;
      color: #b4b4b4;
    `,
    SummaryValue: styled.span`
      font-weight: bold;
      color: white;
      margin-left: 12px;
    `,
    Title: styled.h4`
      text-align: center;
      font-weight: bold;
      color: white;
    `,
    Row: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `,
    TopRow: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: #080913;
      padding: 24px;
    `,
    Ratio: styled.div`
      padding: 12px 24px;
    `,
    FilterRow: styled.div`
      display: flex;
      justify-content: center;
      padding: 24px;
      gap: 16px;
    `
}

const lineChartOptions = {
    scales: {
        yAxes: [{
            ticks: {
                fontColor: "white",
            },
            gridLines: {
                display: false,
            },
        }],
        xAxes: [{
            ticks: {
                fontColor: "white",
            },
            gridLines: {
                color: "rgba(255, 255, 255, .2)",
                borderDash: [5, 5],
                zeroLineColor: "rgba(255, 255, 255, .2)",
                zeroLineBorderDash: [5, 5]
            },
        }]
    },
};


const ReportsOverTime = ({ stats }: { stats: Stats[] }) => {
    const data = {
        labels: stats.map((stat, i) => i % 4 === 0 ? stat.date.replace("2021-", "") : ''),
        datasets: [
            {
                data: stats.map(stat => stat.vehicle_reported_count),
                label: "Reported vehicles",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderColor: "rgba(255, 255, 255, 1)",
                pointBackgroundColor: "rgba(255, 255, 255, 1)",
            },
            {
                data: stats.map(stat => stat.otr_count),
                label: "Vehicles with OTR",
                borderColor: "rgb(36,134,194)",
                pointBackgroundColor: "rgb(36,134,194)",
            },
        ]
    }

    return (
        <S.ReportsOverTime>
            <Line
                data={data}
                options={lineChartOptions}
            />
        </S.ReportsOverTime>
    )
}

const getColorIterator = () => {
    const colors = [
        "#003f5c", "#ffa600", "#f95d6a", "#2f4b7c", "#a05195", "#665191", "#d45087",
        "#ff7c43",'#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ];
    let i = 0;
    return () => {
        if (i === colors.length) {
            i = 0
        }
        return colors[i++]
    }
}

const Ratio = ({ ratio }: { ratio: RatioType }) => {
    const nextColor = getColorIterator()
    const data = {
        labels: ratio.entries.map(entry => entry.key),
        datasets: [{
            label: 'My First Dataset',
            data: ratio.entries.map(entry => entry.value),
            backgroundColor: ratio.entries.map(_ => nextColor()),
            hoverOffset: 4
        }]
    }
    return (
        <S.Ratio>
            <S.Title>% {ratio.name}</S.Title>
            <Doughnut
                width={230}
                height={230}
                data={data}
            />
        </S.Ratio>
    )
}

const formatEur = (v: any) => v && `€${v?.toLocaleString()}`

const Summary = ({ data }: { data: DashboardResponse }) => {
    return (
        <S.Summary>
            <h5>
                <S.SummaryText>
                    Current count OTR:
                </S.SummaryText>
                <S.SummaryValue>
                    {data.stats_of_today.otr_count}
                </S.SummaryValue>
            </h5>
            <h5>
                <S.SummaryText>
                    Vehicles reported today:
                </S.SummaryText>
                <S.SummaryValue>
                    {data.stats_of_today.vehicle_reported_count}
                </S.SummaryValue>
            </h5>
            <h5>
                <S.SummaryText>
                    Vehicles reported total:
                </S.SummaryText>
                <S.SummaryValue>
                    {data.total_vehicles}
                </S.SummaryValue>
            </h5>
            <h5>
                <S.SummaryText>
                    Total value seized:
                </S.SummaryText>
                <S.SummaryValue>
                    {formatEur(data.value_seized)}
                </S.SummaryValue>
            </h5>
            <h5>
                <S.SummaryText>
                    Total value seized by Digitpol:
                </S.SummaryText>
                <S.SummaryValue>
                    {formatEur(data.value_seized_by_digitpol)}
                </S.SummaryValue>
            </h5>
            <h5>
                <S.SummaryText>
                    Total value seized by third parties:
                </S.SummaryText>
                <S.SummaryValue>
                    {formatEur(data.value_seized_others)}
                </S.SummaryValue>
            </h5>
        </S.Summary>
    )
}

interface RatioType {
    name: string;
    entries: { key: string, value: number }[];
}

interface Stats {
    date: string;
    vehicle_reported_count: number;
    otr_count: number;
}

interface DashboardResponse {
    total_vehicles: number;
    value_seized: number;
    value_seized_by_digitpol: number;
    value_seized_others: number;
    ratios: RatioType[];
    stats_of_today: Stats;
    history: Stats[];
    last_added_vehicles: VehicleType[];
}

const vehicleFilterOptions = [
    { value: 'all', label: 'No filter (show all)', searchParams: {} },
    { value: 'stolen', label: 'Only stolen vehicles', searchParams: { status: 'stolen' } },
    { value: 'not_stolen', label: 'Only not stolen vehicles', searchParams: { status: 'not_stolen' } },
    { 
        value: 'stolen_with_kiwa', 
        label: 'Stolen vehicles with SCM class', 
        searchParams: { status: 'stolen', has_kiwa_certificate: 'true' } 
    },
    { 
        value: 'stolen_without_kiwa', 
        label: 'Stolen vehicles without SCM class', 
        searchParams: { status: 'stolen', has_kiwa_certificate: 'false' } 
    },
]

const Dashboard = () => {
    useRouteGuard('canReadVehicles', 'canViewDashboard')
    const [vehicleFilter, setVehicleFilter] = useState(vehicleFilterOptions[0].value)
    
    const vehicleFilterParams = vehicleFilterOptions.find(({ value }) => value === vehicleFilter).searchParams
    const searchParams = new URLSearchParams(vehicleFilterParams)
    
    return (
        <S.Page>
            <S.FilterRow>
                <FormControl
                    sx={{
                        width: 250,
                        minWidth: 250,
                        '& .MuiInputBase-inputSizeSmall': { fontSize: '14px' },
                        '& .MuiInputLabel-root': { color: 'white' },
                        '& .MuiOutlinedInput-root': { color: 'white' },
                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                        '& .MuiSvgIcon-root': { color: 'white' }
                    }}
                    size="small"
                >
                    <InputLabel id="vehicle-filter-options">Vehicle Filter</InputLabel>
                    <Select
                        labelId="vehicle-filter-options"
                        value={vehicleFilter}
                        label="Vehicle Filter"
                        onChange={(e) => setVehicleFilter(e.target.value)}
                    >
                        {vehicleFilterOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </S.FilterRow>

            <Get url={`dashboard/?${searchParams.toString()}`} interval={30}>
                {({ data }: { data: DashboardResponse }) => (
                    <>
                        <S.TopRow>
                            <ReportsOverTime stats={data.history} />
                            <Summary data={data}/>
                        </S.TopRow>

                        <S.Row>
                            {
                                data.ratios.slice(0,4).map(ratio => <Ratio ratio={ratio} key={ratio.name} />)
                            }
                        </S.Row>
                        <S.Row>
                            {
                                data.ratios.slice(4).map(ratio => <Ratio ratio={ratio} key={ratio.name} />)
                            }
                        </S.Row>
                    </>
                )}
            </Get>
        </S.Page>
    )
}


export default Dashboard
